// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Bootstrap icons
@import '~bootstrap-icons/font/bootstrap-icons.css';

@import '~cdbootstrap/css/cdb.css';

@import '~datatables.net-bs5/css/dataTables.bootstrap5.css';

@import "~datatables.net-buttons-bs5/css/buttons.bootstrap5.css";